<template>
  <div class="maxBox">
    <div class="content">
      <div class="location">地址：北京市朝阳区常营鑫兆佳园15号综合楼7层712</div>
      <div class="location">渠道合作及投资：18201619252（苗总）</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.maxBox {
  background: url("../assets/img/bg5.png") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  h1 {
    text-align: center;
    margin: auto;
  }
  .location {
    font-size: 30px;
    line-height: 2em;
  }
}
</style>
